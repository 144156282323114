<template>
  <default-layout :showHeader="false">
    <div class="bg-primary text-white text-center px-6">
      <div class="pt-7">
        <div
          class="h-16 w-16 bg-white rounded-full flex items-center justify-center m-auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8 text-primary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <p class="mt-3 font-bold text-2xl">Yêu cầu tạo khoản ứng thành công</p>
        <p class="text-lg pb-6 mt-1">
          Yêu cầu của bạn dự kiện sẽ được xử lý <br />
          trong vòng 6 giờ làm việc
        </p>
      </div>
    </div>
    <div class="px-6">
      <div class="text-center mt-5">
        <p>
          Bạn vui lòng quan tâm kênh zalo official của Inerloan để theo dõi
          khoản ứng và biết thêm chi tiết
        </p>
        <base-button
          @click="toFacebook"
          class="shadow-1 mt-6 normal-case facebook-btn rounded-md h-10 w-full text-white"
        >
          <span class="inline-flex items-center">
            <span class="w-5 h-5 mr-2"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-messenger"
                class="svg-inline--fa fa-facebook-messenger fa-w-16"
                role="img"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 0 0 122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 0 1-53.91 9.93l-58.08-43.47a15 15 0 0 0-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0 1 53.91-9.93l58.06 43.46a15 15 0 0 0 18 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z"
                />
              </svg>
            </span>
            <span class="relative -bottom-0.5">Liên hệ ngay</span>
          </span>
        </base-button>
        <a
          @click.prevent="wayBackHome"
          class="inline-block mt-8 text-tertiary cursor-pointer"
        >
          Quay lại trang chủ
        </a>
      </div>
    </div>
  </default-layout>
</template>
<script lang="ts">
import BaseButton from '@/components/Base/BaseButton.vue';
import constRouter from '@/constants/constRouter';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { DefaultLayout, BaseButton },
  name: 'BorrowerLoanSuccess',
  methods: {
    wayBackHome() {
      this.$router.push({
        name: constRouter.DASHBOARD.name,
      });
    },
    toFacebook() {
      window.open('https://m.me/interloanp2plending', '_blank');
      // this.$router.push({ name: HOME });
    },
  },
});
</script>
<style lang="scss" scoped>
.facebook-btn {
  background: rgb(24, 119, 242);
}

.home-btn {
  color: #cdcdcd;
}
</style>
