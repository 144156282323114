
import BaseButton from '@/components/Base/BaseButton.vue';
import constRouter from '@/constants/constRouter';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { DefaultLayout, BaseButton },
  name: 'BorrowerLoanSuccess',
  methods: {
    wayBackHome() {
      this.$router.push({
        name: constRouter.DASHBOARD.name,
      });
    },
    toFacebook() {
      window.open('https://m.me/interloanp2plending', '_blank');
      // this.$router.push({ name: HOME });
    },
  },
});
